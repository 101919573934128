<template>
  <EntityTable :api-url="apiUrl" :columns="columns" :title="title" :edit-page="editPage" :delete-url="deleteUrl" edit-page-router="tag"></EntityTable>
</template>
<script>
import { defineComponent } from "vue";
import EntityTable from "@/components/EntityTable";

export default defineComponent({
  name: "tags",
  components: {
    EntityTable,
  },
  data: function () {
    return {
      apiUrl: "admin/tags",
      deleteUrl: "api/admin/tags/delete/",

      editPage: "/admin-dashboard/tag/",
      title: "Теги",
      columns: [
        {
          name: "Tag Id",
          value: "id",
        },
        {
          name: "Name",
          value: "name",
          processor: (item) => {
            return this.$getLocalizedValue(item, this.$i18n.locale)
          },
        },
      ],
    };
  },

  methods: {},
});
</script>
